import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import productReducer from './slices/product';
import elementReducer from './slices/element';
import magictypeReducer from './slices/magictype';
import tagReducer from './slices/tag';
import cardReducer from './slices/card';
import rarityReducer from './slices/rarity';
import spellReducer from './slices/spell';
import characterReducer from './slices/character';

const rootPersistConfig = {
  key: 'root',
  storage,
  // keyPrefix: 'redux-',
  // whitelist: [],
};


const rootReducer = combineReducers({
  element:elementReducer,
  magictype:magictypeReducer,
  tag:tagReducer,
  card:cardReducer,
  rarity:rarityReducer,
  spell:spellReducer,
  character:characterReducer,
  product: productReducer,
});

export { rootPersistConfig, rootReducer };
