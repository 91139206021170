// routes
import { Icon } from '@iconify/react';
import { PATH_DASHBOARD } from '../../../routes/paths';


const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      { title: 'Dashboard', path: '/dashboard/home', icon:<Icon icon="ri:dashboard-line" /> },
      { title: 'Dorm', path: PATH_DASHBOARD.dorm.dorm, icon: <Icon icon="fontisto:world" /> },
      { title: 'Element', path: PATH_DASHBOARD.element.element, icon: <Icon icon="fa6-brands:elementor" /> },
      { title: 'Magic Type', path: PATH_DASHBOARD.magictype.magictype, icon:<Icon icon="game-icons:magic-axe" />},
      { title: 'Tag', path: PATH_DASHBOARD.tag.tag, icon: <Icon icon="solar:tag-linear" /> },
      { title: 'Rarity', path: PATH_DASHBOARD.rarity.rarity, icon: <Icon icon="ant-design:flag-twotone" /> },
      { title: 'Spell', path: PATH_DASHBOARD.spell.spell, icon: <Icon icon="pepicons-pop:fire" />},
      { title: 'Card', path: PATH_DASHBOARD.card.card, icon:<Icon icon="fluent:contact-card-16-regular" /> },
      { title: 'Character', path: PATH_DASHBOARD.character.character, icon: <Icon icon="game-icons:ninja-heroic-stance" /> },
      // { title: 'Character', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
      // { title: 'Card', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },

  // {
  //   subheader: 'management',
  //   items: [
  //     // USER
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'profile', path: PATH_DASHBOARD.user.profile },
  //         { title: 'cards', path: PATH_DASHBOARD.user.cards },
  //         { title: 'list', path: PATH_DASHBOARD.user.list },
  //         { title: 'create', path: PATH_DASHBOARD.user.new },
  //         { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
  //         { title: 'account', path: PATH_DASHBOARD.user.account },
  //       ],
  //     },

  //     // E-COMMERCE
      // {
      //   title: 'e-commerce',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //     { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
      //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //     { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
      //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
      //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //   ],
      // },

  //     // INVOICE
  //     {
  //       title: 'invoice',
  //       path: PATH_DASHBOARD.invoice.root,
  //       icon: ICONS.invoice,
  //       children: [
  //         { title: 'list', path: PATH_DASHBOARD.invoice.list },
  //         { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
  //         { title: 'create', path: PATH_DASHBOARD.invoice.new },
  //         { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
  //       ],
  //     },

      // BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.demoView },
      //     { title: 'create', path: PATH_DASHBOARD.blog.new },
      //   ],
      // },
  //   ],
  // },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: (
  //         <Label variant="outlined" color="error">
  //           +32
  //         </Label>
  //       ),
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban },
  //   ],
  // },
];

export default navConfig;
